// extracted by mini-css-extract-plugin
export var headline = "diaMain-module--headline--3OCD3";
export var title = "diaMain-module--title--IAH2A";
export var titleH = "diaMain-module--titleH--3ja9D";
export var titleS = "diaMain-module--titleS--NLet7";
export var titleDN = "diaMain-module--titleDN--16ijt";
export var titleDNT = "diaMain-module--titleDNT---Lfa_";
export var text = "diaMain-module--text--1J0zi";
export var container1 = "diaMain-module--container1--24V0Q";
export var container2 = "diaMain-module--container2--2ZNB3";
export var container4 = "diaMain-module--container4--V8mPP";
export var container5 = "diaMain-module--container5--3HFKS";
export var container6 = "diaMain-module--container6--1Rmuy";
export var container3 = "diaMain-module--container3--2GNHB";
export var subtitle = "diaMain-module--subtitle--25nnv";
export var image = "diaMain-module--image--2U9sp";
export var imageH = "diaMain-module--imageH--W7qWh";
export var cta = "diaMain-module--cta--1nilO";
export var arrow = "diaMain-module--arrow--3HtEg";
export var arrowT = "diaMain-module--arrowT--1K0Eb";
export var price = "diaMain-module--price--3LaaZ";
export var priceBefore = "diaMain-module--priceBefore--1CfKQ";
export var ctaT = "diaMain-module--ctaT--2tTJy";