import React from "react";
import { cn } from "../lib/helpers";

import * as styles from "./quoteImage.module.css";
import * as commonStyles from "../styles/common.module.css";

import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

import Quote1 from "../images/q1.png";
import Quote2 from "../images/q2.png";

function QuoteImage({ image }) {
  const imageData = getGatsbyImageData(image, {}, clientConfig.sanity);
  return (
    <div>
      <img
        className={cn(styles.brush1, commonStyles.fadeInLeft, commonStyles.animationDelay1)}
        src={Quote1}
        alt="Štětec"
      />
      <GatsbyImage image={imageData} alt={image.alt} />
      <img
        className={cn(styles.brush2, commonStyles.fadeInLeft, commonStyles.animationDelay12)}
        src={Quote2}
        alt="Štětec"
      />
    </div>
  );
}

export default QuoteImage;
