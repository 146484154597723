import React from "react";
import { cn } from "../lib/helpers";

import * as styles from "./brushImage.module.css";
import * as commonStyles from "../styles/common.module.css";

import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

import Brush1 from "../images/brush1.png";
import Brush2 from "../images/brush2.png";

function BrushImage({ image }) {
  const imageData = getGatsbyImageData(image, {}, clientConfig.sanity);
  return (
    <div>
      <img
        className={cn(styles.brush1, commonStyles.fadeInLeft, commonStyles.animationDelay1)}
        src={Brush1}
        alt="Štětec"
      />
      <GatsbyImage image={imageData} alt={image.alt} />
      <img
        className={cn(styles.brush2, commonStyles.fadeInLeft, commonStyles.animationDelay12)}
        src={Brush2}
        alt="Štětec"
      />
    </div>
  );
}

export default BrushImage;
