import React from "react";
import { cn } from "../lib/helpers";
import { Link } from "gatsby";

import * as styles from "./diaMain.module.css";
import * as commonStyles from "../styles/common.module.css";
import PortableText from "./portableText";
import QuoteImage from "./quoteImage";
import BrushImage from "./brushImage";
import Arrow from "../images/sipka.png";

const refContent = [
  {
    _key: "60639c8558de",
    _type: "block",
    children: [
      {
        _key: "db9567498ec1",
        _type: "span",
        marks: [],
        text: "Bála jsem se strašlivě, ale hned při první návštěvě jsem pochopila, že tenoučké jehličky v rukou paní majitelky nejsou můj nepřítel. Naopak! Vpichy většinou téměř nepostřehnete a výsledky se dostavují rychlostí blesku, ať už jde o atopický ekzém, kde v akutní fázi cítíte úlevu do pár dní, nebo o ztrátu hlasu, kdy už večer odzpíváte náročný muzikál. Ze srdce děkuji Beauty Body Clinic za péči a pravidelné chvíle relaxu v jejich útulných pokojíčcích. ",
      },
    ],
    markDefs: [],
    style: "normal",
  },
];

const judContent = [
  {
    _key: "60639c855íde",
    _type: "block",
    children: [
      {
        _key: "db9567498ecě",
        _type: "span",
        marks: [],
        text: "Během stáže v nemocnici čisté tradiční čínské medicíny jsem si uvědomila, že základem úspěchu vyléčení jakéhokoliv zdravotního problému spočívá v podrobné čínské diagnostice a následném stanovení přesné receptury bylinných odvarů spolu s aplikací akupunktury. Díky tomu dochází k dramatickému zlepšení stavu klienta již po několika málo aplikacích. Mojí specializací jsou zejména ekzémy, dermatologické potíže, ženské problémy, problémy s pohybovým aparátem, chronické onemocnění a migrény.",
      },
    ],
    markDefs: [],
    style: "normal",
  },
];

const refImage = {
  _type: "image",
  asset: {
    _id: "image-a6260483264dd9a7c89a058ce730a54f4984af51-3000x4495-jpg",
    _type: "sanity.imageAsset",
    _rev: "dHj8dNQlM5qEmEakO3q8yp",
    _createdAt: "2023-08-24T14:37:06Z",
    _updatedAt: "2023-08-24T14:37:06Z",
    assetId: "a6260483264dd9a7c89a058ce730a54f4984af51",
    extension: "jpg",
    metadata: {
      _type: "sanity.imageMetadata",
      blurHash: "dGCFxD~qB.Io-p%MS#S2PAR*#SsAM{R*aewJozoLWBW;",
      dimensions: {
        _type: "sanity.imageDimensions",
        aspectRatio: 0.6674082313681868,
        height: 4495,
        width: 3000,
      },
      hasAlpha: false,
      isOpaque: true,
      lqip: "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAeABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAABwAGAgP/xAAkEAACAgEEAgIDAQAAAAAAAAABAgMEAAUREiEGEzFRFCJBUv/EABYBAQEBAAAAAAAAAAAAAAAAAAMEAv/EABwRAQADAAIDAAAAAAAAAAAAAAEAAgMEEjFBUf/aAAwDAQACEQMRAD8A9Hpw1/C9SHHc+rfbDG9BQkr1YoC3P1hnVvkH+4h6jcJ0LUU32Upgn5jZlhu05IXfi0QG/wAd/WY7JcJdlgPFvs+kJnb9ULclA/0cs6kcyOWLdnLFkcaJNTSfRLak9mI4YeQnlTrq7ci/7RgHfFRPHqcunskbzRhl4nY79ZnbHhVRrqyCR/x4x1GT2D9jDaij8lGHLcc75V8Wh3Hot+RQ6wtscsR53WB/Wqnio2GWJAn/2Q==",
      palette: {
        _type: "sanity.imagePalette",
        darkMuted: {
          _type: "sanity.imagePaletteSwatch",
          background: "#553638",
          foreground: "#fff",
          population: 6.83,
          title: "#fff",
        },
        darkVibrant: {
          _type: "sanity.imagePaletteSwatch",
          background: "#940615",
          foreground: "#fff",
          population: 3.89,
          title: "#fff",
        },
        dominant: {
          _type: "sanity.imagePaletteSwatch",
          background: "#827d79",
          foreground: "#fff",
          population: 7.85,
          title: "#fff",
        },
        lightMuted: {
          _type: "sanity.imagePaletteSwatch",
          background: "#bda99e",
          foreground: "#000",
          population: 4.87,
          title: "#fff",
        },
        lightVibrant: {
          _type: "sanity.imagePaletteSwatch",
          background: "#fb7d8f",
          foreground: "#000",
          population: 0,
          title: "#fff",
        },
        muted: {
          _type: "sanity.imagePaletteSwatch",
          background: "#827d79",
          foreground: "#fff",
          population: 7.85,
          title: "#fff",
        },
        vibrant: {
          _type: "sanity.imagePaletteSwatch",
          background: "#bf051f",
          foreground: "#fff",
          population: 4.1,
          title: "#fff",
        },
      },
    },
    mimeType: "image/jpeg",
    originalFilename: "image1 (2).jpeg",
    path: "images/sfqldr6j/production/a6260483264dd9a7c89a058ce730a54f4984af51-3000x4495.jpg",
    sha1hash: "a6260483264dd9a7c89a058ce730a54f4984af51",
    size: 7472398,
    uploadId: "vQmUNVi59H1polrwhbSgGx8BQeKSPTMu",
    url: "https://cdn.sanity.io/images/sfqldr6j/production/a6260483264dd9a7c89a058ce730a54f4984af51-3000x4495.jpg",
    id: "image-a6260483264dd9a7c89a058ce730a54f4984af51-3000x4495-jpg",
    children: [],
    internal: {
      type: "SanityImageAsset",
      contentDigest: "d03fb28ed016becbd55d5ceb7989988b",
      owner: "gatsby-source-sanity",
      counter: 1176,
    },
    parent: null,
  },
};

const judImage = {
  alt: "judita halvova",
  crop: null,
  hotspot: null,
  asset: {
    _id: "image-89641b5acdd9cc9a0d1717d1b1270fc7138da501-1000x1500-jpg",
    metadata: {
      lqip: "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAeABQDASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUEBgMHCP/EACQQAAIBAwUAAQUAAAAAAAAAAAECAwAEEQUGEiExEyJBQlFh/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwIE/8QAGREAAwEBAQAAAAAAAAAAAAAAAAERMQIS/9oADAMBAAIRAxEAPwDoWJEnskBIyB7SSdEfPxsrjOMqcimdoji3Ck4Rl9qm28dvt3U109J5HjuHZ8v2FYnyj9TRFzRi9sC2cUUxKd+UVRIr0ncTanBPbtA0DxAhOXXPH6qoaOsuq7gFzPyJE/EKxzxxWDcOuzwQwz/nGwI49CpO1NVWa+tjHCEBkLt/Saz26OlEbaa0R+yPtRUhT9IzRTgn/9k=",
      dimensions: {
        aspectRatio: 0.6666666666666666,
        width: 1000,
        height: 1500,
      },
    },
  },
};

const ekzemImage = {
  alt: "Před a po",
  crop: null,
  hotspot: null,
  asset: {
    _id: "image-0fdfaa17d44d2bb9756533d74cad8cb888c3681f-2815x2016-jpg",
    metadata: {
      lqip: "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAOABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQGB//EACMQAAIBAwIHAQAAAAAAAAAAAAECAwAEBQYREhMhIjEyUUL/xAAVAQEBAAAAAAAAAAAAAAAAAAADBf/EABsRAQACAgMAAAAAAAAAAAAAAAEAAgMhERMx/9oADAMBAAIRAxEAPwCrxeSsrfT8qkqUKs7of1TGlbzH2cJO6BnTtHnt87VhWH1jPFazWssfMBGyn5TF1qmfFTWyRpxLyR03+0DS3NpTM2A6teG5TZe0sbrITT48LHDIxbgJ9Tv1FFRC5iSYtIVALksaKYEJNvajZQn/2Q==",
      dimensions: {
        aspectRatio: 1.3963293650793651,
        width: 2815,
        height: 2016,
      },
    },
  },
};

function DiaMain() {
  return (
    <div className={styles.container1}>
      <h1 className={cn(styles.headline, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        Diagnostika
      </h1>
      <h2
        className={cn(
          styles.titleH,
          styles.titleS,
          commonStyles.colorAccent,
          commonStyles.fontButlerMedium
        )}
      >
        {
          "Trápí vás atopický ekzém, alopecie, máte bolesti pohybového aparátu, migrény, ženské problémy nebo chronické nemoci, se kterými si váš lékař neví rady?"
        }
      </h2>
      <h2 className={cn(styles.titleH, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        {"My máme řešení!"}
      </h2>
      <div className={cn(styles.container4)}>
        <div className={styles.imageH}>
          <BrushImage
            image={{
              ...ekzemImage,
              alt: "Před a po",
            }}
          />
        </div>
        <div className={cn(styles.subtitle, commonStyles.colorAccent, commonStyles.fontReleway)}>
          {"Rozdíl po dvou aplikacích akupunktury a týdnu bylinné kúry"}
        </div>
        <div className={cn(styles.container3, commonStyles.colorDarkGray, commonStyles.fontButler)}>
          <h2
            className={cn(styles.titleDN, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
          >
            {"Diagnostika podle TCM"}
          </h2>
          <p className={cn(commonStyles.colorDarkGray, commonStyles.fontButler, styles.ctaT)}>
            Právě teď si můžete objednat poukázku na osobní či online diagnostiku s Juditou Halvovou
            za akční cenu.
          </p>
          <a href="https://shop.bbclinic.cz/konzultace" title="TCM Diagnostika" target="_blank">
            <div className={cn(styles.cta, commonStyles.colorAccent, commonStyles.fontReleway)}>
              Objednat
            </div>
          </a>
          <div className={cn(styles.container5)}>
            <div className={cn(styles.container6)}>
              <div
                className={cn(styles.subtitle, commonStyles.colorAccent, commonStyles.fontReleway)}
              >
                {"Online 30 minut"}
              </div>
              <div
                className={cn(
                  styles.priceBefore,
                  commonStyles.colorDarkGray,
                  commonStyles.fontButler
                )}
              >
                {"2.000 Kč"}
              </div>
              <div className={cn(styles.price, commonStyles.colorAccent, commonStyles.fontButler)}>
                {"1.000 Kč"}
              </div>
            </div>
            <div className={cn(styles.container6)}>
              <div
                className={cn(styles.subtitle, commonStyles.colorAccent, commonStyles.fontReleway)}
              >
                {"Osobní 60 minut"}
              </div>
              <div
                className={cn(
                  styles.priceBefore,
                  commonStyles.colorDarkGray,
                  commonStyles.fontButler
                )}
              >
                {"3.000 Kč"}
              </div>
              <div className={cn(styles.price, commonStyles.colorAccent, commonStyles.fontButler)}>
                {"1.500 Kč"}
              </div>
            </div>
          </div>
        </div>
        <div className={cn(commonStyles.colorDarkGray, commonStyles.fontReleway, styles.arrowT)}>
          Přečtěte si více
        </div>
        <img className={styles.arrow} src={Arrow} alt="Šipka" />
      </div>
      <h2 className={cn(styles.title, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        {"O návratu z Číny"}
      </h2>
      <div className={cn(styles.container2)}>
        <div className={styles.image}>
          <BrushImage
            image={{
              ...judImage,
              alt: "Judita Halvová",
            }}
          />
        </div>
        <div className={cn(styles.container3, commonStyles.colorDarkGray, commonStyles.fontButler)}>
          <h2
            className={cn(styles.titleDN, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
          >
            {"Judita Halvová:"}
          </h2>
          <h3
            className={cn(styles.titleDNT, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
          >
            {'„Tajemství tradiční čínské medicíny tkví v bylinných odvarech na míru"'}
          </h3>
          <PortableText blocks={judContent} />
          <div className={cn(styles.subtitle, commonStyles.colorAccent, commonStyles.fontReleway)}>
            <Link to={"/magazin/ma-cesta-za-akupunkturou"}>
              Přečtěte si rozhovor o mé cestě do Číny
            </Link>
          </div>
        </div>
      </div>
      <h2 className={cn(styles.title, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        {"Řekli o nás"}
      </h2>
      <div className={cn(styles.container2)}>
        <div className={cn(styles.container3, commonStyles.colorDarkGray, commonStyles.fontButler)}>
          <h2
            className={cn(styles.titleDN, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
          >
            {"Denisa Nesvačilová:"}
          </h2>
          <h3
            className={cn(styles.titleDNT, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
          >
            {
              "Dlouholeté trápení s atopickým ekzémem vyřešila až akupunktura a bylinné odvary na míru"
            }
          </h3>
          <PortableText blocks={refContent} />
          <div className={cn(styles.subtitle, commonStyles.colorAccent, commonStyles.fontReleway)}>
            {`Denisa Nesvačilová – herečka, zpěvačka`}
          </div>
        </div>
        <div className={styles.image}>
          <QuoteImage
            image={{
              ...refImage,
              alt: "Denisa Nesvačilová",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default DiaMain;
